<template>
  <div>
    <div class="xs-p-20 xs-fill-white">
      <div class="form-section first-section mycontainer-main p-10" >
        <p><strong> {{$t('localization.select_your_home')}}:</strong></p>
        <p>{{$route.params.title}} </p>
        <div>
          <ul class="list-group" v-if="resultList.length >0">
            <li class="list-group-item" v-for="item in resultList" :key="item.id" @click="checkValidOutlet(item)">
              <a href="javascript: void(0)" style="text-decoration: none">
                {{item.address}}
              </a>
            </li>
          </ul>
          <p v-else>{{message}}</p>
        </div>

      </div>
    </div>
  </div>

</template>
<script>
import ConfirmOrderAdvance from '../components/popup/ConfirmOrderAdvance.vue'
export default {
  components: {
  },
  mounted () {
    this.searchZipCode = this.$route.params.zip
    this.initData()
  },
  data () {
    return {
      searchZipCode: '',
      resultList: '',
      message: '',
      result: {},
      orderType: this.$const.DELIVERY
    }
  },
  methods: {
    initData () {
      this.resultList = []
      this.message = ''
      if (!this.searchZipCode) {
        return false
      }
      var vm = this
      this.$bus.$emit('loading', 'block')
      this.axios.apiOutlet.getOutletByAddressCode({zip_code: this.searchZipCode}, function (response) {
        let resultList = response.data.data.items
        // console.log(response.data.data.items)
        vm.resultList = resultList
        if (vm.resultList.length <= 0) {
          vm.message = vm.$t('localization.not_store')
        }
        vm.$bus.$emit('loading', 'none')
      }, function (error) {
        console.log(`error: ${error}`)
        vm.message = vm.$t('localization.not_store')
        vm.$bus.$emit('loading', 'none')
      })
    },
    /**
     * Checking outlet is available
     * */
    checkValidOutlet (outlet) {
      // check time outlet
      var vm = this
      let data = {order_type: this.orderType, collection_time: this.$disposition.getByKey(this, 'collectionTimeText', 'Now'), advance_order: this.$disposition.getByKey(this, 'advanceOrder', 'N'), outlet_id: outlet.id}
      this.axios.apiOutlet.checkOutletAvailable(data,
        function (response) {
          response = response.data
          if (response.status) {
            let data = vm.$disposition.get(vm)
            // set data
            data.lat = outlet.lat
            data.long = outlet.long
            data.postalCode = outlet.zip_code
            data.locationName = outlet.kana_address
            data.outletAddress = outlet.address
            data.orderType = vm.orderType
            data.outletId = outlet.id
            data.outletName = outlet.name
            data.outletCode = outlet.outlet_code
            data.advanceOrder = vm.$disposition.getByKey(vm, 'advanceOrder', 0) || 0
            data.tier = outlet.tier
            vm.$disposition.set(vm, data)
            vm.$router.push({'name': 'category'})
          } else if (response.message && response.message != '') {
            vm.$notify({
              type: 'danger',
              title: response.message
            })
          }
        },
        function (error) {
          let message = false
          if (error.message) {
            message = error.message
          }
          vm.$modal.show(ConfirmOrderAdvance, {message: message, outletId: vm.result.outletId})
        }
      )
    }
  }
}
</script>
<style scoped>
  ul,ol{
    list-style-type: none;
  }
  .p-10 {
    padding: 10px !important;
  }

</style>
